@import "src/helpers/constants";

.nav_bar_wrapper {
  transition: left .2s;

  .nav_bar {
    width: 300px;
    background-color: $dark_color;
    height: 100vh;
    padding: $main-paddings;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .nav_info_div {
      min-height: 173px;
    }

    .logo {
      width: 100%;
      margin-bottom: 20px;
    }

    .name {
      color: white;
      font-size: 13px;
      font-family: Roboto-Medium, sans-serif;
      margin-bottom: 8px;
    }

    .change_profile {
      color: white;
      font-size: 13px;
      font-family: Roboto-RegularItalic, sans-serif;
      text-decoration: underline;
      margin-bottom: 8px;
      cursor: pointer;
      transition: all .2s;

      &:hover {
        color: $main-color;
      }

      &:active {
        opacity: 0.5;
      }
    }

    .info {
      color: white;
      font-family: Roboto-LightItalic, sans-serif;
      font-size: 13px;
      margin-bottom: 8px;
      max-width: 100%;
      overflow-x: auto;
    }
    .info2 {
      color: white;
      font-family: Roboto-Light, sans-serif;
      font-size: 13px;
      margin-bottom: 8px;
    }
    .mail {
      color: white;
      font-family: Roboto-Light, sans-serif;
      font-size: 13px;
      display: inline-block;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 20px 0;
    }

    .nav_list {
      margin-bottom: 20px;

      .section {
        margin-bottom: 10px;

        .title {
          color: white;
          font-family: Roboto-Medium, sans-serif;
          font-size: 18px;
          margin-bottom: 6px;
        }

        .nav_item {
          display: flex;
          align-items: center;
          padding: 10px 0 10px 12px;
          cursor: pointer;
          border-radius: 2px;
          position: relative;
          text-decoration: none;
          transition: background-color .2s;

          &.active {
            .nav_item_counter {
              background-color: white;
              color: $text-color;
            }
          }

          &:hover {
            background-color: $main-color;
          }

          &:active {
            background-color: $main-color-darker;
          }

          &.disabled {
            opacity: 0.4;
            cursor: revert;

            &:hover {
              background-color: $dark-color;
            }

          }

          &_img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            object-fit: contain;
          }

          &_text {
            color: white;
            font-family: Roboto-Light, sans-serif;
            font-size: 16px;
            text-decoration: none;
          }

          &_counter {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: $main-color;
            right: 8px;
            top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-family: Roboto-Medium, sans-serif;
            font-size: 16px;
          }
        }
      }
    }

    .footer {
      color: white;
      font-family: Roboto-Light, sans-serif;
      font-size: 12px;
    }
  }

  .open_menu {
    display: none;
    position: absolute;
    padding: 10px 13px;
    top: 74px;
    right: -50px;
    z-index: 1000;
    color: white ;
    background-color: $dark-color;

    .open_menu_image {
      width: 24px;
      height: 24px;
    }
  }
}

/*@media screen and (max-width: 1280px){
  .nav_bar_wrapper {
    position: absolute;
    z-index: 10;
    left: -300px;

    &.show {
      left: 0;
    }

    .open_menu {
      display: block;

      .open_menu_image.show {
        transform: rotate(180deg);
      }
    }
  }
}*/
