@import "src/helpers/constants";

.viol_photo_modal {
  &_title {
    color: white;
    font-size: 30px;
    height: 44px;
    padding-top: 4px;
    font-family: Roboto-Medium, sans-serif;
  }

  .selectFileButton {
    display: flex;
    justify-content: center;
    margin-top: 40vh;
  }

  .image_wrapper {
    .image {
      height: calc(100vh - 300px);

      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        max-height: calc(100vh - 300px);
        //max-height: 100px;
      }
    }

    .image_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin-top: 60px;
    }
  }
}
