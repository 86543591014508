.wrapper {
  margin-top: 40px;
  width: 600px;
}

.title {
  font-size: 22px;
  font-family: Roboto-Regular, sans-serif;
  margin-bottom: 20px;

  &Small {
    font-size: 16px;
    font-family: Roboto-Regular, sans-serif;
    margin-bottom: 14px;
  }

  &Info {
    font-size: 14px;
    font-family: Roboto-LightItalic, sans-serif;
    margin-bottom: 14px;
    color: #a6a6a6;
  }
}

.checkboxes {
  margin-bottom: 30px;

  .checkbox {
    margin-bottom: 10px;

    &Item {
      width: unset;
    }

    &Label {
      font-family: Roboto-Light, sans-serif !important;
    }
  }

  .emptyText {
    font-size: 14px;
    font-family: Roboto-Light, sans-serif;
    text-align: center;
  }
}

.input {
  &Info {
    font-family: Roboto-Light, sans-serif !important;
    font-size: 14px !important;
  }
}

.buttonsRow {
  display: flex;
  margin-top: 30px;
}
