@import "src/helpers/constants";

.sortable_list_wrapper {
  position: relative;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .row {
      display: flex;
    }

    .header_checkbox {
      width: 304px;
    }

    .refresh_button {
      width: 42px;
      height: 42px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
    .refresh {
      width: 18px;
      height: 18px;

      &::selection {
        opacity: 0;
      }
    }

    .search_input_div {
      width: 300px;
      height: 42px;
      margin-left: 6px;
      margin-bottom: 0;

      .search_input {
        margin: 0;
        height: 42px;
      }
    }
  }

  .sortable_list_container {
    margin-top: 40px;
  }

  .nothing_found_text {
    color: $text-color;
    font-size: 18px;
    margin-top: 40px;
    text-align: center;
    font-family: Roboto-Regular, sans-serif;
  }

  .page_row {
    margin: 40px 0;
    padding: 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .page_item {
      margin: 6px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto-Light, sans-serif;
      color: $text-color;
      border: solid 1px $light-border-color;
      transition: background-color .2s;

      &:hover {
        background-color: $light-background-color;
      }

      &:active {
        background-color: $label-color;
      }

      &.page_item_active {
        background-color: $main-color;
        color: white;
      }
    }
  }
}

.submit_delete {
  margin-right: 12px;
}

.delete_row {
  display: flex;
  justify-content: center;
}

.delete_text {
  margin-bottom: 20px;
  font-family: Roboto-Regular, sans-serif;
  font-size: 18px;
  color: $text-color;

  & span {
    margin-left: 4px;
    font-family: Roboto-Bold, sans-serif;
  }
}
