@import "src/helpers/constants";

.checkups_wrapper {
  .header_row, .item_row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $light-border-color;

    .item {
      padding: 10px 14px;
      font-size: 14px;
      color: $text-color;
      font-family: Roboto-Medium, sans-serif;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      max-height: 85px;

      &.first {
        width: 10%;
      }
      &.second {
        width: 10%;
      }
      &.third {
        width: 10%;
      }
      &.fourth {
        width: 15%;
      }
      &.fifth {
        width: 10%;
      }
      &.sixth {
        width: 10%;
      }
      &.seventh {
        width: 10%;
      }
      &.eights {
        width: 10%;
      }
      &.ninth {
        width: 10%;
      }
      &.tenth {
        width: 10%;
      }

      &.pressable span:hover {
        text-decoration: underline;
      }

      &.column {
        flex-direction: column;
      }

      .appointment_date {
        font-family: Roboto-Medium, sans-serif;
        margin-top: 6px;
        font-size: 12px;
      }

      .order_text {
        border: 1px solid $main-color;
        padding: 4px 8px;
        font-family: Roboto-Regular, sans-serif;
        color: $main-color;
        font-size: 12px;
      }

      .prescription_number {
        color: $main-color;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .cloud_button {
      width: 34px;
      height: 34px;
      padding: 0;
      justify-content: center;
    }
  }

  .item_row {
    transition: background-color .2s;

    &.clickable {
      cursor: pointer;
    }

    .item {
      font-family: Roboto-Light, sans-serif;
      padding: 16px 14px;
    }
    &:hover {
      background-color: $light-background-color;
    }
  }
  .header_buttons_row {
    display: flex;

    .header_button {
      margin-right: 10px;
    }
  }

  .refresh_button {
    width: 42px;
    height: 42px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }
  .refresh {
    width: 18px;
    height: 18px;

    &::selection {
      opacity: 0;
    }
  }

  .filter_buttons_row {
    display: flex;

    .filter_item {
      width: 300px;
      margin-right: 20px;
    }
  }

  .checkups_container {
    margin-top: 20px;
  }

  .nothing_found_text {
    color: $text-color;
    font-size: 18px;
    margin-top: 40px;
    text-align: center;
    font-family: Roboto-Regular, sans-serif;
  }
}

.checkup_request_modal {
  width: 300px;
  &_title {
    color: $text-color;
    font-size: 18px;
    font-family: Roboto-Medium, sans-serif;
    margin-bottom: 30px;
  }

  &_select_wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    & p {
      color: $text-color;
      font-family: Roboto-Regular, sans-serif;
      margin-top: 9px;
    }
  }

  &_button {
    display: flex;
    margin-top: 100px;
    justify-content: center;
  }
}
